<template>
    <div>
      <v-skeleton-loader
        elevation="2"
        :loading="loading"
        type="table-thead, divider, table-tbody"
      >
        <slot> </slot>
      </v-skeleton-loader>
    </div>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style>

</style>