<template>
  <v-container fluid>
    <v-row>
        <v-col cols="12" md="12">
            <v-card dark outlined>
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> mdi-filter </v-icon>
                {{header}} Filters
                <v-spacer></v-spacer>
                <v-btn elevation="2" class="mr-2" x-small>Show Additional Filters</v-btn>
            </v-card-title>

            <v-card-text> 
               <v-row>
                 <v-col cols="12" md="2">
                    <v-datetime-picker
                        v-model="startDate"
                        :text-field-props="textFieldPropsStartDate"
                        :date-picker-props="datePropsStartDate"
                        :time-picker-props="timePropsStartDate"
                        date-format="dd/MM/yyyy"
                    ></v-datetime-picker>

                    <div>
                  Event Start:
                  <br />
                  {{ formattedStartTime }}
                  <br />
                  {{ formattedStartDate }}
                </div>
                    <!-- End of day :- {{dateTimeEOD(EOD.DateTime)}} -->
                 </v-col>
                 <v-col cols="12" md="2">
                    <v-datetime-picker
                        v-model="endDate"
                        :text-field-props="textFieldPropsEndDate"
                        :date-picker-props="datePropsEndDate"
                        :time-picker-props="timePropsEndDate"
                        date-format="dd/MM/yyyy"
                    ></v-datetime-picker>

                    <div>
                  Event End:
                  <br />
                  {{ formattedEndTime }}
                  <br />
                  {{ formattedEndDate }}
                </div>
                 </v-col>
                 <v-col cols="12" md="2">
                    <global-MultipleSelect ref="stallHoldersRef" :label="labelStallHolders" :dataList="allStallHolders" :itemText="StallHolderName" :itemValue="StallHolderId" @CheckVisibility="haddleCheckVisibility" @checkSelectedList="haddleSelectedList">
                    </global-MultipleSelect>
                 </v-col>
                 <v-col cols="12" md="2">
                    <global-MultipleSelect ref="stallRef" :label="labelStall" :dataList="stalls" :itemText="StallName" :itemValue="StallId" :isDisable="isDisableStall" @checkSelectedList="haddleSelectedListTerminal">
                    </global-MultipleSelect>
                 </v-col>
                 <v-col cols="12" md="2">
                    <global-MultipleSelect ref="terminalNumberRef" :label="labelTerminalNumber" :dataList="terminal" :itemText="terminalName" :itemValue="terminalId" :isDisable="isDisableStall">
                    </global-MultipleSelect>
                 </v-col>
                 <v-col cols="12" md="2" class="text-right">
                  <v-btn
                  color="secondary"
                  class="mt-4 white--text mr-4 d-inline-flex"
                  @click="handleRefresh"
                >
                  <v-icon dark class="mx-auto">mdi-refresh</v-icon>
                </v-btn>
                   <v-btn
                        color="secondary"
                        class="mt-4 white--text"
                        @click="haddleClickSubmit"
                    >
                        Submit
                        <v-icon right dark>mdi-magnify</v-icon>
                    </v-btn>
                 </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
 </template>
 
<script>
import moment from 'moment'
import {
  mapState,
  mapMutations
} from 'vuex'
export default {
  props: {
    header: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      startDate: new Date(),
      allStallHoldersList: [],
      textFieldPropsStartDate: {
        appendIcon: 'event'
      },
      datePropsStartDate: {
        headerColor: 'black'
      },
      timePropsStartDate: {
        useSeconds: false,
        ampmInTitle: true
      },
      endDate: new Date(),
      textFieldPropsEndDate: {
        appendIcon: 'event'
      },
      datePropsEndDate: {
        headerColor: 'black'
      },
      timePropsEndDate: {
        useSeconds: false,
        ampmInTitle: true
      },
      labelStallHolders: 'Stallholders',
      StallHolderName: 'StallholderName',
      StallHolderId: 'StallHolderId',

      labelStall: 'Stall',
      StallName: 'StallName',
      StallId: 'StallId',

      labelTerminalNumber: 'Terminal Number',
      terminalName: 'TerminalNumber',
      terminalId: 'TermId',

      selectedStallHolders: [],
      stalls: [],
      selectedStall: [],
      terminal: [],
      selectedTerminal: [],

      isDisableStall: false,
      QueryStrings: {},
      formattedStartTime: '',
      formattedStartDate: '',
      formattedEndTime: '',
      formattedEndDate: '',
    }
  },
  created() {
     this.startDate = this.searchStartTime !== 'notSet' && this.searchStartTime ? moment(this.searchStartTime, 'YYYYMMDDHHmm').toDate() : null;
     this.endDate = this.searchEndTime !== 'notSet' && this.searchEndTime ? moment(this.searchEndTime, 'YYYYMMDDHHmm').toDate() : null;
     this.fetchStallHolders();
     if (this.globalEventFilter.EventId) {
    // const eventObject = this.allFetchedEvents.find(event => event.EventId === this.globalEventFilter.EventId);
    // if (eventObject) {
      this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
      this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
      this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
      this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
    // }
    
  }
  },
  computed: {
    ...mapState('filter', ['filterStallHolders',
    // 'filterStalls', 
    // 'filterTerminals',
    'allStallHolders','allStalls',
    // 'allTerminals',
    // 'EOD',
    'globalEventFilter', 'searchStartTime','searchEndTime', 'globalEventFilter',
    // 'allEvents', 
    // 'allFetchedEvents',
    // 'globalSelectedEvent'
  ])
  },
  watch: {
  startDate(newVal) {
    if (newVal) {
      this.formattedStartTime = moment(newVal).format('HH:mm');
      this.formattedStartDate = moment(newVal).format('DD/MM/YYYY');
    }
  },
  endDate(newVal) {
    if (newVal) {
      this.formattedEndTime = moment(newVal).format('HH:mm');
      this.formattedEndDate = moment(newVal).format('DD/MM/YYYY');
    }
  },
  'globalEventFilter.EventId'(newVal) {
    if (newVal) {
      this.fetchStallHolders(newVal);
      // const eventObject = this.allFetchedEvents.find(event => event.EventId === newVal);
      // if (eventObject) {
        this.formattedStartTime = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('h:mm A');
        this.formattedStartDate = moment(this.globalEventFilter.startDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
        this.formattedEndTime = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('h:mm A');
        this.formattedEndDate = moment(this.globalEventFilter.endDateTime, 'YYYYMMDDHHmm').format('DD MMM YY');
      // }
    }
    this.setSearchStartTime('notSet');
    this.setSearchEndTime('notSet');
    
    this.startDateTimeSet();
    this.endDateTimeSet();
      
     // Fetch stallholders when EventId changes
   
    this.updateDashboard();
  },
},
  mounted: function() {
        var dash='-'
        if (this.globalEventFilter.startDateTime !== '') {
          var startdateTime = this.globalEventFilter.startDateTime
          var startdt = startdateTime.substring(0,4) + dash + startdateTime.substring(4,6) + dash + startdateTime.substring(6,8)
            + ' ' + startdateTime.substring(8,10) + ':' + startdateTime.substring(10,12);
          this.startDate = startdt;
          } else {
            this.startDate = new Date()
          }
        if (this.globalEventFilter.endDateTime !== '') {
          var endDateTime = this.globalEventFilter.endDateTime
          var endDT = endDateTime.substring(0,4) + dash + endDateTime.substring(4,6) + dash + endDateTime.substring(6,8)
            + ' ' + endDateTime.substring(8,10) + ':' + endDateTime.substring(10,12);
          this.endDate = endDT;
          } else {
            this.endDate = new Date()
          }
      },
  methods: {
    ...mapMutations('filter', ['setFilterStallHolders',
    // 'setFilterStalls',
    // 'setFilterTerminals'
  ]),
    haddleCheckVisibility: function () {
      if (this.$refs.stallHoldersRef.selectedList.length === 0) {
        this.isDisableStall = true;
      } else {
        this.isDisableStall = false;
      }
    },
    haddleSelectedList: function (value) {
      if (value.length > 0) {
        this.stalls = [];
        value.forEach((obj) => {
            obj.Stalls.forEach((data) => {
              data.StallHolderName = obj.StallHolderName
              this.stalls.push(data)
            });
        });
      }
    },
    haddleSelectedListTerminal: function (value) {
      if (value.length > 0) {
        this.terminal = [];
        value.forEach((obj) => {
            obj.Terminals.forEach((data) => {
              this.terminal.push(data)
            });
        });
      }
    },
    handleRefresh: function(){
      this.updateDashboard()
    },
    dateTimeEOD(value) {
      return moment(value, "HH:mm").format('HH:mm A');
    },
    haddleClickSubmit: function () {
      let sDate = moment(this.startDate).format('YYYYMMDDHHmm');
      let eDate = moment(this.endDate).format('YYYYMMDDHHmm');
      let stallHolder = this.$refs.stallHoldersRef.selectedList;
      let stall = this.$refs.stallRef.selectedList;
      let terminalNumber = this.$refs.terminalNumberRef.selectedList;
      
      this.selectedStallHolders = stallHolder.map(r => {
        return r.StallHolderId;
      });

      this.selectedStall = stall.map(r => {
        return r.StallId;
      });

      this.selectedTerminal = terminalNumber.map(r => {
        return r.TermId;
      });

      alert("Transactions - ")
      this.QueryStrings.startTime = sDate;
      this.QueryStrings.endTime = eDate
      this.QueryStrings.stallholders = this.selectedStallHolders
      this.QueryStrings.stalls = this.selectedStall
      this.QueryStrings.terminal = this.selectedTerminal
      console.log(this.QueryStrings)
    },
    fetchStallHolders() {
      // const eventObject = this.allFetchedEvents.find(event => event.EventId === eventId);
      if (this.globalEventFilter.StallHolders) {
        this.allStallHoldersList = this.globalEventFilter.Stallholders;
      } else {
        this.allStallHoldersList = [];
      }
      this.globalEventFilter.Stallholders.forEach((obj) => {
        
            this.allStallHoldersList.push(obj)
        });
      // console.log('==----- Event: ', eventObject); 
      // console.log('==----- EventObject Stall Holders : ', eventObject.Stallholders); 
      // console.log('==-----Stall Holders: ', this.allStallHoldersList); 
    }
    
  }
}
</script> 