/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'
// import Qs from 'qs'

export default class TakingsAPI extends Dashboard {
  constructor() {
    super()
  }
  
  encodeDataToURL = (params) => {
    let urlVals = Object
      .keys(params)
      .map(value => `${value}=${encodeURIComponent(params [value])}`)
      .join('&');
    return urlVals
  }

  getTotalSales(context) {
    
    // console.log('getTotalTransactions context: ', context)
    // console.log('getTotalTransactions searchStallholders: ', context.searchStallholders)
    
  // getTotals(context) {
  //   let accessToken = localStorage.getItem('accessToken')
  //   let accessUser = localStorage.getItem('accessUser')
  //   return this.api.get('/components/home?user='+ accessUser + '&accessToken=' + accessToken, {
  //     params: {
  //       eventId: context.eventId
  //     }
  //   }).then(response => {
  //     return response.data
  //   })
  // }

    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')

    let params = {
      eventId: context.eventId,
      startTime: context.searchStartTime,
      endTime: context.searchEndTime,
      stallholders: context.searchStallholders,
      stalls: context.searchStalls,
    }
    
    // console.log('PARAMS CHECK: ', params)
    let newParams = this. encodeDataToURL(params)
    // console.log('newParams: ', newParams)


    return this.api.get('/components/takings?user='+ accessUser + '&accessToken=' + accessToken + '&' + newParams
    // {
    //   params: {
    //     eventId: context.eventId,
    //     startTime: context.searchStartTime,
    //     endTime: context.searchEndTime,
    //     stallholders: context.searchStallholders,
    //     stalls: context.searchStalls,
    //   },
    //   paramsSerializer: params => {
    //     return Qs.stringify(params, {arrayFormat: 'repeat'})
    //   }
    // }
    ).then(response => {
      return response.data
    })
  }

  // getStallComparison(context) {
  //   return this.api.get('/takingsStallComparison', {
  //     params: {
  //       eventId: context.eventId,
  //       stalls: context.stalls
  //     },
  //     paramsSerializer: params => {
  //       return Qs.stringify(params, {arrayFormat: 'repeat'})
  //     }
  //   }).then(response => {
  //     return response.data
  //   })
  // }

}