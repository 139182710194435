  <template>
  <v-container fluid>
    <v-row>
        <v-col cols="12" md="12">
            <v-card dark outlined>
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> home </v-icon>
                {{header}}
            </v-card-title>

            <v-card-text> 
               <v-row>
                  <v-col cols="12" md="2" class="MT-2">
                    <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectedStallHolder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stallholders"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                                mdi-information
                            </v-icon>
                            No event found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-datetime-picker
                        v-model="startDate"
                        :text-field-props="textFieldPropsStartDate"
                        :date-picker-props="datePropsStartDate"
                        :time-picker-props="timePropsStartDate"
                        time-format="HH:mm"
                    ></v-datetime-picker>
                 </v-col>
                 <v-col cols="12" md="2">
                   <v-text-field
                    v-model="terminalID"
                    label="Terminal ID"
                    required
                  ></v-text-field>
                 </v-col>
                 <v-col cols="12" md="2">
                    <v-text-field
                    v-model="stan"
                    label="STAN"
                    required
                  ></v-text-field>
                 </v-col>
                 <v-col cols="12" md="2">
                   <v-btn
                        color="secondary"
                        class="ma-3 white--text"
                        @click="haddleClickSubmit"
                    >
                        Submit
                        <v-icon right dark>mdi-magnify</v-icon>
                    </v-btn>
                 </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
 </template>
 
<script>
import moment from 'moment'
import {
  mapState
} from 'vuex'
export default {
  props: {
    header: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedStallHolder: '',

      startDate: new Date(),
      textFieldPropsStartDate: {
        appendIcon: 'event'
      },
      datePropsStartDate: {
        headerColor: 'black'
      },
      timePropsStartDate: {
        useSeconds: false,
        ampmInTitle: true
      },
      terminalID: '',
      stan: ''
    }
  },
  computed: {
        ...mapState('filter', ['allStallHolders']),
  },
  methods: {
    haddleClickSubmit: function () {
      let sDate = moment(this.startDate).format('YYYYMMDDHHmm');
      alert("sart date - " + sDate)
    }
  }
}
</script>