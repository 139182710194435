/**
* Application routes defined here
*/

export default [
  {
    path: '/login',
    view: 'Login',
    name: 'Login',
    meta: {
      title: "eventPOS Portal | Login",
      AuthNotRequired: true
    }
  },
  {
    path: '/',
    view: 'Home', // Relative to src/views/
    name: 'Home', 
    meta: {
      title: "eventPOS Portal | Home",
    }
  },
  {
    path: '/AutoLogin',
    view: 'Main/AutoLogin',
    name: 'AutoLogin',
    meta: {
      title: "eventPOS Portal | AutoLogin"
    }
  },

  {
    path: '/Devices',
    view: 'EventManagement/Devices/devices',
    name: 'Devices',
    meta: {
      title: "eventPOS Portal | Devices"
    }
  },

  {
    path: '/device_configuration/:id',
    view: 'EventManagement/Devices/deviceConfiguration',
    name: 'Device Configuration',
    meta: {
      title: "eventPOS Portal | Device Configuration",
    }
  },

  {
    path: '/ChargebackSearch',
    view: 'Main/ChargebackSearch',
    name: 'ChargebackSearch',
    meta: {
      title: "eventPOS Portal | Chargeback Search"
    }
  },
  {
    path: '/ClientsEvents',
    view: 'Main/ClientsEvents',
    name: 'ClientsEvents',
    meta: {
      title: "eventPOS Portal | Clients Events"
    }
  },
  {
    path: '/EventSettings',
    view: 'Main/EventSettings',
    name: 'EventSettings',
    meta: {
      title: "eventPOS Portal | Event Settings",
    }
  },
  {
    path: '/PortalUsers',
    view: 'Main/PortalUsers',
    name: 'PortalUsers',
    meta: {
      title: "eventPOS Portal | Portal Users",
    }
  },
  {
    path: '/PortalGroups',
    view: 'Main/PortalGroups',
    name: 'PortalGroups',
    meta: {
      title: "eventPOS Portal | Portal Groups",
    }
  },
  {
    path: '/Products',
    view: 'Main/Products',
    name: 'Products',
    meta: {
      title: "eventPOS Portal | Products",
    }
  },
  {
    path: '/ProductSales',
    view: 'ProductSales',
    name: 'ProductSales',
    meta: {
      title: "eventPOS Portal | Product Sales",
    }
  },
  {
    path: '/Administration',
    view: 'Main/Administration',
    name: 'Administration',
    meta: {
      title: "eventPOS Portal | Admin",
    }
  },
  {
    path: '/ProductStoresStockLevels',
    view: 'Main/ProductStoresStockLevels',
    name: 'ProductStoresStockLevels',
    meta: {
      title: "eventPOS Portal | Product Stores Stock Levels",
    }
  },
  {
    path: '/SourceProductsMappings',
    view: 'Main/SourceProductsMappings',
    name: 'SourceProductsMappings',
    meta: {
      title: "eventPOS Portal | Source Products Mappings",
    }
  },
  {
    path: '/EventManagement',
    view: 'Main/EventManagement',
    name: 'EventManagement',
    meta: {
      title: "eventPOS Portal | Event Management",
    }
  },
  {
    path: '/ProductManagement',
    view: 'Main/ProductManagement',
    name: 'ProductManagement',
    meta: {
      title: "eventPOS Portal | Product Management",
    }
  },
  {
    path: '/StockLevels',
    view: 'StockLevels',
    name: 'StockLevels',
    meta: {
      title: "eventPOS Portal | Stock Levels"
    }
  },
  {
    path: '/Takings',
    view: 'Takings',
    name: 'Takings',
    meta: {
      title: "eventPOS Portal | Takings"
    }
  },
  {
    path: '/Transactions',
    view: 'Transactions',
    name: 'Transactions',
    meta: {
      title: "eventPOS Portal | Transactions"
    }
  },
  {
    path: '/VoucherSetup',
    view: 'Main/VoucherSetup',
    name: 'VoucherSetup',
    meta: {
      title: "eventPOS Portal | VoucherSetup"
    }
  },

  {
    path: '/event_configuration/:id',
    view: 'EventManagement/Events/eventConfiguration',
    name: 'Event Configuration',
    meta: {
      title: "eventPOS Portal | Event Configuration",
    }
  },

  {
    path: '/stallholder_configuration',
    view: 'EventManagement/Stallholders/stallholderConfiguration',
    name: 'Stallholders Configuration',
    meta: {
      title: "eventPOS Portal | Stallholders Configuration",
    }
  },

  {
    path: '/user_configuration',
    view: 'EventManagement/Users/userConfiguration',
    name: 'User Configuration',
    meta: {
      title: "eventPOS Portal | User Configuration",
    }
  },

  {
    path: '/stall_configuration/:id',
    view: 'EventManagement/Stalls/stallConfiguration',
    name: 'Stall Configuration',
    meta: {
      title: "eventPOS Portal | Stall Configuration",
    }
  },

  {
    path: '/terminal_configuration/:id',
    view: 'EventManagement/Terminals/terminalConfiguration',
    name: 'Terminal Configuration',
    meta: {
      title: "eventPOS Portal | Terminal Configuration",
    }
  },

  {
    path: '/layout_configuration/:id',
    view: 'ProductManagement/Layouts/LayoutConfiguration',
    name: 'Layout Configuration',
    meta: {
      title: "eventPOS Portal | Layout Configuration",
    }
  },

  {
    path: '/layout_configuration1/:id',
    view: 'ProductManagement/Layouts/LayoutConfigurationL1',
    name: 'Layout Configuration 1',
    meta: {
      title: "eventPOS Portal | Layout Configuration",
    }
  },

  {
    path: '/layout_configuration2/:id',
    view: 'ProductManagement/Layouts/LayoutConfigurationL2',
    name: 'Layout Configuration 2',
    meta: {
      title: "eventPOS Portal | Layout Configuration 2",
    }
  },

  {
    path: '/layout_configuration3/:id',
    view: 'ProductManagement/Layouts/LayoutConfigurationL3',
    name: 'Layout Configuration 3',
    meta: {
      title: "eventPOS Portal | Layout Configuration 3",
    }
  },

]