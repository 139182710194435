/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'
import Qs from 'qs'

export default class TransactionsAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getTransactions(context) {
    console.log(context)
    return this.api.get('/transactionsTransactions', {
      params: {
        "eventId": "412",
        "startkey": "001",
        "itemcount": "4",
        "terminalIds": [
          "15809",
          "15389",
          "15390"
        ]
      },
      paramsSerializer: params => {
        return Qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }).then(response => {
      return response.data
    })
  }
  
}