/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'

export default class HomeAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getTotals(context) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.get('/components/home?user='+ accessUser + '&accessToken=' + accessToken, {
      params: {
        eventId: context.eventId,
        startTime: context.searchStartTime,
        endTime: context.searchEndTime
      }
    }).then(response => {
      return response.data
    })
  }
  
  getTopStalls(context) {
    return this.api.get('/homeTop10Stalls', {
      params: {
        eventId: context.eventId,
        dashboardComponent: 'homeTop10Stalls'
      }
    }).then(response => {
      return response.data
    })
  }

  getTopProducts(context) {
    return this.api.get('/homeTop10Products', {
      params: {
        eventId: context.eventId,
        dashboardComponent: 'homeTop10Products'
      }
    }).then(response => {
      return response.data
    })
  }

  getTopTerminals(context) {
    return this.api.get('/homeTop10Terminals', {
      params: {
        eventId: context.eventId,
        dashboardComponent: 'homeTop10Terminals'
      }
    }).then(response => {
      return response.data
    })
  }
  
}