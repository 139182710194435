/**
* Wrapper around the Authclass from aws-amplify library
*/

import axios from 'axios'

export default class Dashboard {
  constructor() {
    this.api = axios.create({
        baseURL: process.env.VUE_APP_DASHBOARDAPI_URL,
        timeout: 120000, // after 10s request timeouts
    })
  }

  setHeader(token) {
    this.api.defaults.headers.Authorization = token
  }

}