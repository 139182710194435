<template>
  <v-btn
    dark
    :block="block"
    :outlined="outlined"
    @click="logoutClicked"
  >
  <v-icon dense left>mdi-logout</v-icon>
  Logout
</v-btn>

</template>

<script>
import {
  mapMutations
} from 'vuex'

export default {
  props: {
    outlined: Boolean,
    block: Boolean
  },
  methods: {
    ...mapMutations('app', ['setUserLoggedIn', 'setDrawer']),
    logoutClicked() {
      this.$authentication.signOut().then(() => {
        // this.setUserLoggedIn(false)
        this.$router.push({
          path: '/login',
          query: {
            redirect: '/'
          }
        })
        this.setUserLoggedIn(false)
        this.setDrawer(false)
      }).catch(err => {
        console.log(err)
        console.log("logout error")
      })
    }
  }
}
</script>

<style>

</style>