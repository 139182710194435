<script>
import { Doughnut } from 'vue-chartjs'
/* eslint-disable no-unused-vars */

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartdata(newData, oldData) {
      this.renderChart(newData, this.options)
    },
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>

</style>