import { set } from '@/utils/vuex'

export default {
  // Home
  setHomeTotals: set('homeTotals'),
  setHomeTop10Stalls: set('homeTop10Stalls'),
  setHomeTop10Products: set('homeTop10Products'),
  setHomeTop10Terminals: set('homeTop10Terminals'),

  setHomeTotalsLoading: set('homeTotalsLoading'),
  setHomeTopStallsLoading: set('homeTopStallsLoading'),
  setHomeTopProductsLoading: set('homeTopProductsLoading'),
  setHomeTopTerminalsLoading: set('homeTopTerminalsLoading'),

  // Takings
  setTakingsTotalSales: set('takingsTotalSales'),
  setTakingsStallComparison: set('takingsStallComparison'),

  setTakingsTotalSalesLoading: set('takingsTotalSalesLoading'),
  setTakingsStallComparisonLoading: set('takingsStallComparisonLoading'),

  // Product Sales
  setProductSalesTotalTransactions: set('productSalesTotalTransactions'),
  setProductSalesProducts: set('productSalesProducts'),
  setProductSalesCategories: set('productSalesCategories'),
  setProductSalesStallComparison: set('productSalesStallComparison'),
  
  setProductSalesTotalTransactionsLoading: set('productSalesTotalTransactionsLoading'),
  setProductSalesProductsLoading: set('productSalesProductsLoading'),
  setProductSalesCategoriesLoading: set('productSalesCategoriesLoading'),
  setProductSalesStallComparisonLoading: set('productSalesStallComparisonLoading'),

  // transactions
  setTransactionsTransactions: set('transactionsTransactions'),

  setAllLoading: (state, payload) => {
    state.homeTotalsLoading = payload
    state.homeTopStallsLoading = payload
    state.homeTopProductsLoading = payload
    state.homeTopTerminalsLoading = payload

    state.takingsTotalSalesLoading = payload
    state.takingsStallComparisonLoading = payload

    state.productSalesTotalTransactionsLoading = payload
    state.productSalesProductsLoading = payload
    state.productSalesCategoriesLoading = payload
  }
}
