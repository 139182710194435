/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "tsg-eventpos-dashboard-hosting-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://dvicvsaafbwsk.cloudfront.net",
    Auth: {
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
      }
};


export default awsmobile;
