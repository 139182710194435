/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'

export default class DevicesAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getLayouts(payload) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.get('/admin/terminallayouts?user='+ accessUser + '&accessToken=' + accessToken, {
      params: payload
    }).then(response => {
      return response.data
    })
  }
  
  postLayouts(context) { 
    // console.log('Starting services/dashboardAPIs/layoutsAPI.js postLayouts context',context)
    // console.log('Starting services/dashboardAPIs/layoutsAPI.js postLayouts payload',payload)
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/admin/terminallayouts?user='+ accessUser + '&accessToken=' + accessToken, context
    ).then(response => {
      console.log('RESPONSE: ',response)
      return response.data
    })
  }
  
}