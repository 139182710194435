<template>
    <div>
      <v-skeleton-loader
          elevation="2"
          type="list-item-avatar, card-heading"
          :loading="loading"
        >
          <slot></slot>
        </v-skeleton-loader>
    </div>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style>

</style>