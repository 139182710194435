<template>
  <v-simple-table light dense fixed-header :height="height">
    <template v-slot:default>
    <thead>
        <tr class="primary">
          <th class="text-left white--text black"
            v-for="(heading, index) in tableHeadings"
            :key="index"
          >
            {{ heading }}
          </th>
        </tr>
    </thead>
    <tbody>
        <tr
          v-for="(row,index) in tableData.slice(0, 10)"
          :key="index"
        >
          <td v-if="ranks">
            {{ index + 1}}
          </td>
          <td
            v-for="(col, colIndex) in tableDataProperties"
            :key="colIndex"
          >
            {{ row[col] }}
          </td>
        </tr>
    </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["fixedHeader", "height", "ranks", "tableHeadings", "tableData", "tableDataProperties"]

}
</script>

<style>

</style>