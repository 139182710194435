  <template>
  <v-container fluid>
    <v-row>
        <v-col cols="12" md="12">
            <v-card dark outlined>
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> home </v-icon>
                {{header}}
            </v-card-title>

            <v-card-text> 
               <v-row>
                 <v-col cols="12" md="1">
                    <v-select
                     :items="ClientList"
                     label="Client List"
                    ></v-select>
                 </v-col>
                 <v-col cols="12" md="1">
                    <v-select
                     :items="event"
                     label="Event"
                    ></v-select>
                 </v-col>
                 <v-col cols="12" md="2">
                    <v-select
                     :items="stallHolderList"
                     label="Stallholder List"
                    ></v-select>
                 </v-col>
                 <v-col cols="12" md="2">
                    <v-select
                     :items="stallHolders"
                     label="Stallholders"
                    ></v-select>
                 </v-col>
                 <v-col cols="12" md="6">
                   <v-btn
                        color="secondary"
                        class="ma-3 white--text"
                        @click="loader = 'loading3'"
                    >
                        Deploy Profile to Stall
                        <v-icon right dark>mdi-plus</v-icon>
                    </v-btn>
                     <v-btn
                        color="secondary"
                        class="ma-3 white--text"
                        @click="loader = 'loading3'"
                    >
                        Copy Profile to Stallholder
                        <v-icon right dark>mdi-plus</v-icon>
                    </v-btn>
                 </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
 </template>
 
<script>
export default {
  props: {
    header: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ClientList: ['Client 1', 'Client 2', 'Client 3', 'Client 4'],
      stallHolders: ['Stallholders 1', 'Stallholders 2', 'Stallholders 3', 'Stallholders 4'],
      stallHolderList: ['SH-1', 'SH-2', 'SH-3', 'SH-4', 'SH-5'],
      event: ['event 1', 'event 2', 'event 3'],
    }
  }
}
</script>