<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :height="height"
      :search="search"
      :headers="headers"
      :items="items"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ["height", "search", "headers", "items"],
}
</script>

<style>

</style>