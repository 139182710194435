<template>	
    <v-menu	
      :close-on-content-click="false"	
      transition="slide-y-transition"
      top
      offset-y
      v-model="menu"
    >	
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs"	v-on="on" fab color="primary" fixed right bottom
          class="d-flex d-md-none"
        >
          <v-icon dark>mdi-filter</v-icon>
        </v-btn>	
      </template>	
      <global-eventSelectorCard/>
    </v-menu>	
</template>	

<script>

export default {	
  data: () => ({	
    menu: false
  })
}	
</script>	

<style>	
</style> 