<template>
  <v-navigation-drawer
    v-model="drawerValue"
    absolute
    temporary
    dark
    color="#272b30"
    >
    <v-list-item>
      <v-list-item-content >
        <v-list-item-title>
          <v-img src="@/assets/logo.png" max-width="100"></v-img>
          <br>
        </v-list-item-title>        
        <v-list-item-subtitle class="pt-2">
          <v-icon> mdi-account </v-icon>
          {{ userInfo.username }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider/>

    <v-list dense class="py-0">
      <v-subheader> Event Management</v-subheader>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <br>
    <v-divider/>

    <v-list dense class="py-0" v-if="IsAdmin">
      <v-subheader> Admin Control</v-subheader>
      <v-list-item v-for="item in adminItems" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <br>
    <v-divider/>
    
    <v-list dense class="py-0">
      <v-subheader> Stock Control </v-subheader>
      <v-list-item v-for="item in stockControlItems" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <br>

    <v-divider/>
    <div class="pa-2">
      <utility-LogoutButton block outlined/>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {
 mapMutations,
 mapState
} from 'vuex'

export default {
  data: () => ({
    items: [
      { title: 'Portal Users', icon: 'mdi-account', to:"PortalUsers"},
      { title: 'Portal Groups', icon: 'mdi-account', to:"PortalGroups"},
      { title: 'Event Management', icon: 'mdi-eventbrite', to:"EventManagement"},
      { title: 'Product Management', icon: 'mdi-shopping', to:"ProductManagement"},
    ],
    adminItems: [
      { title: 'Administration', icon: 'mdi-sitemap', to:"Administration"}
    ],
    stockControlItems: [
      { title: 'Product Stores & Stock Levels', icon: 'mdi-speedometer', to:"ProductStoresStockLevels"},
      { title: 'Source Products & Mappings', icon: 'mdi-map', to:"SourceProductsMappings"},
    ]
  }),
  computed: {
    ...mapState('app', ['userInfo']),
    ...mapState('filter', ['IsAdmin']),
    drawerValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer'])
  }
}
</script>

<style>
.menulist {
  background-color: "#272b30";
}
</style>