<template>	
    <v-menu	
      :close-on-content-click="false"	
      transition="slide-y-transition"
      bottom
      offset-y
      v-model="menu"	
    >	
      <template v-slot:activator="{ on, attrs }">	
        <v-btn 	
            v-bind="attrs"	
            v-on="on" color="secondary" elevation="1" class="mx-6" > <v-icon left> mdi-menu-down 
        </v-icon> Events </v-btn>	
      </template>	
      <global-eventSelectorCard/>
    </v-menu>	
</template>	

<script>

export default {	
  data: () => ({	
    menu: false
  })
}	
</script>	

<style>	
</style> 