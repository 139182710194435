/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'

export default class LoginAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getAllData(context) {
    return this.api.get('/logon', {
      params: {
        user: context.user,
        accessToken: context.accessToken
      }
    }).then(response => {
      return response.data
    })
  }
  
}
