/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'

export default class ReportsAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getReports(payload) {
    console.log('Starting reportsAPI.js getReports payload',payload)
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/reports?user='+ accessUser + '&accessToken=' + accessToken,payload
    ).then(response => {
      return response.data
    })
  }
  
}