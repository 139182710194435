/**
* pdf-lib class
*/
/* eslint-disable no-unused-vars */


export default class GenerateColors {
  constructor() {
    this.defaultColors = [
      'rgb(255, 99, 132)',
      'rgb(165, 42, 42)',
      'rgb(255, 99, 71)',
      'rgb(255, 140, 0)',
      'rgb(218, 165, 32)',
      'rgb(107, 142, 35)',
      'rgb(127, 255, 0)',
      'rgb(0, 255, 127)',
      'rgb(100, 149, 237)',
      'rgb(139, 0, 139)',
      'rgb(255, 192, 203)',
      'rgb(210, 105, 30)',
    ]
  }

  getColors(colorslength) {
    let availableLength = this.defaultColors.length
    let result = []
    for (let i = 0; i < colorslength; i++) {
      i %= availableLength;
      result.push(this.defaultColors[i])
    }
    return result
  }

}