<template>
  <div>
  <!-- <v-navigation-drawer app> -->
    <!-- -->
  <!-- </v-navigation-drawer> -->

  <!-- <v-app-bar app> -->
    <!-- -->
  <!-- </v-app-bar> -->
    <!-- FOOTER -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>