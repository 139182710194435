import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import signup from '../views/Signup.vue' THIS LETS YOU USE signup in the component section below.
import paths from './routes'
import { dashboardAPI } from '@/services'
import { Auth } from 'aws-amplify'

function route (path, view, name, props, meta) {
  return {
    meta: meta,
    name: name || view,
    props: props || false,
    path,
    component: (resolve) => import(
      `@/views/${view}.vue`
    ).then(resolve)
  }
}

Vue.use(VueRouter)

// Create a new router
const router = new VueRouter({
  // mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name, path.props, path.meta)).concat([
    { path: '*', redirect: "/" }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

/**
 * Before navigating to a route perform some checks
 */
router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => !record.meta.AuthNotRequired)) {
    try {
      let user = await Auth.currentAuthenticatedUser();
      dashboardAPI.setHeader(user.signInUserSession.idToken.jwtToken)
      if(!store.state.app.userLoggedIn) {
        let setUserLoggedIn = store._mutations['app/setUserLoggedIn'][0];
        let setUserInfo = store._mutations['app/setUserInfo'][0];
        setUserLoggedIn(true)
        setUserInfo(user)
      }
      next();
    } catch (e) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    }
  } else {
    next()
  }
  
  // let setUserLoggedIn = store._mutations['app/setUserLoggedIn'][0];
  // let setUserInfo = store._mutations['app/setUserInfo'][0];
  // setUserLoggedIn(true)
  // next();
});

export default router
