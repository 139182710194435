/**
* Dashboard api for the home page
*/

import Dashboard from '../dashboard'

export default class AdminGroupsEventsAPI extends Dashboard {
  constructor() {
    super()
  }
  
  getGroups() {
    return this.api.get('/admin/groups').then(response => {
      return response.data
    })
  }
  
  getEvents() {
    return this.api.get('/admin/events').then(response => {
      return response.data
    })
  }
  
  // Event Create / Update / Detete
  createEvent(context) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/admin/eventmanagement?user='+ accessUser + '&accessToken=' + accessToken, 
        context).then(response => {
      return response.data
    })
  }

  createCategories(context) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/admin/productmanagement?user='+ accessUser + '&accessToken=' + accessToken, 
        context).then(response => {
      return response.data
    })
  }

  // Events by Ids
  getEventById(context) {
    return this.api.get('/admin/eventmanagementget', {
      params: {
        Type: context.Type,
        Id: context.id
      }
    }).then(response => {
      return response.data
    })
  }

  getProducts(context) {
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/admin/productmanagement?accessToken=' + accessToken, {
      params: {
        EventId: context.EventId,
        StallholderId: context.StallholderId
      }
    }).then(response => {
      return response.data
    })
  }

  getLayouts(context) {
    console.log("getLayouts context")
    console.log(context)
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/admin/terminallayouts?accessToken=' + accessToken, {
      params: {
        stallholderId: '0000000000000786'
        // stallholderId: context.StallholderId
      }
    }).then(response => {
      return response.data
    })
  }

  getDevices() {
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/admin/devices?accessToken=' + accessToken).then(response => {
      return response.data
    })
  }

  getColours() {
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/globaladmin/colours?accessToken=' + accessToken).then(response => {
      return response.data
    })
  }

  createColour(context) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/globaladmin/colours?user='+ accessUser + '&accessToken=' + accessToken, 
        context).then(response => {
      return response.data
    })
  }

  createPortalUsers(context) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.post('/admin/usersgroups?user='+ accessUser + '&accessToken=' + accessToken, 
        context).then(response => {
      return response.data
    })
  }

  getRecentevent(context) {
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/recentevent?accessToken=' + accessToken, {
      params: {
        eventId: context
      }
    }).then(response => {
      return response.data
    })
  }

  getEventsByYear(selectedYear) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.get('/event_selector/getevents?user='+ accessUser + '&accessToken=' + accessToken + '&year=' + selectedYear, {}).then(response => {
      return response.data
    })
  }

  getEventMetadata(eventId) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.get('/event_selector/geteventmetadata?user='+ accessUser + '&accessToken=' + accessToken + '&EventId=' + eventId, {}).then(response => {
      return response.data
    })
  }

  getEventsData(selectedYear) {
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')
    return this.api.get('/eventmanagement/geteventsdata?user='+ accessUser + '&accessToken=' + accessToken + '&year=' + selectedYear, {}).then(response => {
      return response.data
    })
  }

  getPortalUsers() {
    let accessToken = localStorage.getItem('accessToken')
    return this.api.get('/admin/usersgroups?accessToken=' + accessToken).then(response => {
      return response.data
    })
  }
  
}