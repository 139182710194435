export default {
    // Home page
    homeTotals: {},
    homeTop10Stalls: [],
    homeTop10Products: [],
    homeTop10Terminals: [],

    homeTotalsLoading: true,
    homeTopStallsLoading: true,
    homeTopProductsLoading: true,
    homeTopTerminalsLoading: true,

    // Takings page
    takingsTotalSales: {},
    takingsStallComparison: [],

    takingsTotalSalesLoading: true,
    takingsStallComparisonLoading: true,

    // Product Sales page
    productSalesTotalTransactions: {},
    productSalesProducts: {},
    productSalesCategories: {},
    productSalesStallComparison: [],

    productSalesTotalTransactionsLoading: true,
    productSalesProductsLoading: true,
    productSalesCategoriesLoading: true,
    productSalesStallComparisonLoading: true,

    //transactions
    transactionsTransactions: []
  }