/**
* Wrapper around the Authclass from aws-amplify library
*/

import AdminGroupsEventsAPI from './dashboardAPIs/admin/groupsEventsAPI'
import HomeAPI from './dashboardAPIs/homeAPI'
import ProductSalesAPI from './dashboardAPIs/productSalesAPI'
import LoginAPI from './dashboardAPIs/loginAPI'
import TakingsAPI from './dashboardAPIs/takingsAPI'
import TransactionsAPI from './dashboardAPIs/transactionsAPI'
import ReportsAPI from './dashboardAPIs/reportsAPI'
import DevicesAPI from './dashboardAPIs/devicesAPI'
import LayoutsAPI from './dashboardAPIs/layoutsAPI'

export default class DashboardAPI {
  constructor() {
    this.adminGroupsEventsAPI = new AdminGroupsEventsAPI()
    this.homeAPI = new HomeAPI()
    this.productSalesAPI = new ProductSalesAPI()
    this.takingsAPI = new TakingsAPI()
    this.loginAPI = new LoginAPI()
    this.transactionsAPI = new TransactionsAPI()
    this.reportsAPI = new ReportsAPI()
    this.devicesAPI = new DevicesAPI()
    this.layoutsAPI = new LayoutsAPI()
  }

  getAdminGroupsEventsAPI() {
    return this.adminGroupsEventsAPI
  }

  getHomeAPI() {
    return this.homeAPI
  }

  getProductSalesAPI() {
    return this.productSalesAPI
  }

  getTakingsAPI() {
    return this.takingsAPI
  }

  getLoginAPI() {
    return this.loginAPI
  }

  getTransactionsAPI() {
    return this.transactionsAPI
  }

  getReportsAPI() {
    return this.reportsAPI
  }

  getDevicesAPI() {
    return this.devicesAPI
  }

  postDevicesAPI() {
    // console.log('postDevicesAPI triggered')
    return this.devicesAPI
  }

  getLayoutsAPI() {
    // console.log('getLayoutsAPI triggered')
    return this.layoutsAPI
  }

  postLayoutsAPI() {
    // console.log('postLayoutsAPI triggered')
    return this.layoutsAPI
  }

  setHeader(token) {
    this.adminGroupsEventsAPI.setHeader(token)
    this.homeAPI.setHeader(token)
    this.productSalesAPI.setHeader(token)
    this.takingsAPI.setHeader(token)
    this.loginAPI.setHeader(token)
    this.transactionsAPI.setHeader(token)
    this.reportsAPI.setHeader(token)
    this.devicesAPI.setHeader(token)
    this.layoutsAPI.setHeader(token)
    // set header for other API's
  }

}