/**
* pdf-lib class
*/
/* eslint-disable no-unused-vars */

import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'

import download from 'downloadjs'

export default class PdfEditor {
  constructor() {
    this.fontSize = 10;
  }

  async createPdf(PDFName, transaction) {
    const pdfDoc = await PDFDocument.create()

    const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);

    
// const textWidth = helveticaFont.widthOfTextAtSize(text, textSize);
// const textHeight = helveticaFont.heightAtSize(textSize);

    const page = pdfDoc.addPage()
    const { width, height } = page.getSize()
    
    page.setFontSize(this.fontSize);
    page.moveTo(40, (height - 4 * this.fontSize));

    this.writeText(page, helveticaBold, 'TAX INVOICE');
    this.writeText(page, helvetica, transaction.EventName);
    this.writeText(page, helvetica, transaction.StallHolderName);
    this.writeText(page, helvetica, transaction.StallName);
    this.writeText(page, helvetica, `ABN: ${transaction.ABN}`); // TODO: missing
    this.writeText(page, helvetica, `TRANSACTION: ${transaction.TransactionID}`);
    this.writeText(page, helvetica, `TERMINAL No: ${transaction.TerminalNumber}`);
    this.writeText(page, helvetica, `TERMINAL ID: ${transaction.TerminalID}`);
    this.writeText(page, helvetica, `DATE: ${transaction.DateCreated}`); // TODO: missing
    this.writeText(page, helvetica, `TIME: ${transaction.TimeCreated}`);
    
    
    
    const pdfBytes = await pdfDoc.save()
    download(pdfBytes, `${PDFName}.pdf`, "application/pdf");
    return pdfBytes
  }

  writeText(page, fontStyle, text) {
    page.moveDown(this.fontSize + 5);
    page.drawText(text, {
      font: fontStyle
    })
  }


}