<template>
    <v-expansion-panels popout dark accordion class="">
      <v-expansion-panel
      >
        <v-expansion-panel-header class="secondary justify-self-start py-0">
          <div class="">
            <v-icon left dense> mdi-filter </v-icon>
            <span class="mr-2">Filters</span> 
            <v-chip label light small class="mx-2">
              <v-icon left>
                mdi-calendar-clock
              </v-icon>
              {{ globalYearFilter.EventYear || '--'}}
            </v-chip>
            <v-chip label light small class="mx-2">
              <v-icon left>
                mdi-food-fork-drink
              </v-icon>
              {{ globalEventFilter.EventName || '--' }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-0 my-0">
          <div class="my-0">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="6"
                v-if="!disableDates"
              >
              <v-datetime-picker v-model="fromTime" dateFormat="dd/MM/yyyy" label="From Date/Time" :text-field-props="textFieldProps" :disabled="disableDates">
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
              </v-col>
              
              <v-col
                cols="12"
                md="2"
                sm="6"
                v-if="!disableDates"
              >
              <v-datetime-picker v-model="toTime" dateFormat="dd/MM/yyyy" label="To Date/Time" :text-field-props="textFieldProps" :disabled="disableDates">
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
              </v-col>

              <v-col
                cols="12"
                md="2"
                sm="6"
                v-if="!disableStallHolders"
              >
              <v-autocomplete
                v-model="selectedStallHolders"
                :items="allStallHolders"
                item-text="StallHolderName"
                return-object
                label="Stallholders"
                filled
                outlined
                dense
                chips
                small-chips
                deletable-chips
                multiple
                clearable
                open-on-clear
                hide-details
                :disabled="disableStallHolders"
              >
                <template #prepend-inner>
                  <v-tooltip color="accent" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs" 
                        v-on="on" 
                        @click="selectAllStallHolders" 
                        v-show="selectedStallHolders.length == 0 && !disableStallHolders"> mdi-library-plus </v-icon>
                    </template>
                    <span>Select All</span>
                  </v-tooltip>
                </template>

                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index == 0 && selectedStallHolders.length == allStallHolders.length">
                    <span>All Selected ({{selectedStallHolders.length}})</span>
                  </v-chip>

                  <v-chip small v-if="index < 2 && selectedStallHolders.length != allStallHolders.length">
                    <span>{{ item.StallHolderName }}</span>
                  </v-chip>
                  <span
                    v-if="index == 2 && selectedStallHolders.length != allStallHolders.length"
                    class="grey--text caption"
                  >
                    (+{{ selectedStallHolders.length - 2 }} others)
                  </span>
                </template>
              </v-autocomplete>

              </v-col>

               <v-col
                cols="12"
                md="2"
                sm="6"
                v-if="!disableStalls"
              >

              <v-autocomplete
                v-model="selectedStalls"
                :items="allStalls"
                item-text="StallName"
                return-object
                label="Stalls"
                filled
                outlined
                dense
                chips
                small-chips
                deletable-chips
                multiple
                clearable
                open-on-clear
                hide-details
                :disabled="disableStalls"
              >
                <template #prepend-inner>
                  <v-tooltip color="accent" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs" 
                        v-on="on" 
                        @click="selectAllStalls" 
                        v-show="selectedStalls.length == 0 && !disableStalls"> mdi-library-plus </v-icon>
                    </template>
                    <span>SELECT ALL</span>
                  </v-tooltip>
                </template>

                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index == 0 && selectedStalls.length == allStalls.length">
                    <span>All Selected ({{selectedStalls.length}})</span>
                  </v-chip>

                  <v-chip small v-if="index < 2 && selectedStalls.length != allStalls.length">
                    <span>{{ item.StallName }}</span>
                  </v-chip>
                  <span
                    v-if="index == 2 && selectedStalls.length != allStalls.length"
                    class="grey--text caption"
                  >
                    (+{{ selectedStalls.length - 2 }})
                  </span>
                </template>
              </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="2"
                sm="6"
                v-if="!disableTerminals"
              >
                <v-select
                  filled
                  dense
                  hide-details
                  outlined
                  multiple
                  v-model="selectedTerminals"
                  label="Terminals"
                  :items="terminals"
                  :disabled="disableTerminals"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="selectAllTerminals"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedTerminals.length > 0 ? 'grey darken-4' : ''">
                          {{ terminalSelectAllIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">

                    
                    <v-chip small v-if="index == 0 && selectedTerminals.length == terminals.length">
                      <span>All Selected ({{selectedTerminals.length}})</span>
                    </v-chip>

                    <v-chip small v-if="index < 2 && selectedTerminals.length != terminals.length">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span
                      v-if="index == 2 && selectedTerminals.length != terminals.length"
                      class="grey--text caption"
                    >
                      (+{{ selectedTerminals.length - 2 }})
                    </span>
                  </template>
                  
                </v-select>
              </v-col>

              <v-col
                style="border: 0px solid red"
                v-if="!disableButtons"
              >
                <!-- <v-btn elevation="1" small class="ma-1" color="secondary">
                  <v-icon small left> mdi-calendar </v-icon>
                  Entire Event
                </v-btn> -->
                
                <!-- <v-btn elevation="1" small class="ma-1" color="secondary">
                  <v-icon small left> mdi-clock </v-icon>
                  Today
                </v-btn> -->
                
                <v-btn elevation="1" small class="mx-1" color="secondary" @click="testCall">
                  <v-icon small left> mdi-magnify</v-icon>
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
// import moment from 'moment'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'

// import axios from 'axios'
// import Qs from 'qs'

export default {
  props: {
    disableDates: {
      type: Boolean,
      default: false
    },
    disableStalls: {
      type: Boolean,
      default: false
    },
    disableStallHolders: {
      type: Boolean,
      default: false
    },
    disableTerminals: {
      type: Boolean,
      default: false
    },
    disableButtons: {
      type: Boolean,
      default: false
    },
  },
  // props: ['disableDates', 'disableStalls', 'disableStallHolders', 'disableTerminals', 'disableButton'],
  data: () => ({
    fromTime: new Date(),
    toTime: new Date(),
    
    //stalls
    // stalls: [
    //   {text: "VILLAGE BAR", value: "stall1"},
    //   {text: "YALUMBA BAR", value: "stall2"},
    //   {text: "VINE ROOM", value: "stall3"},
    //   {text: "WO SHOP", value: "stall4"},
    // ],

    
    //stallHolders
    // stallHolders: [
    //   {text: "Holder 1", value: "holder1"},
    //   {text: "Holder 2", value: "holder2"},
    //   {text: "Village bar holder", value: "holder3"},
    //   {text: "Wo Shop holder", value: "holder4"},
    // ],

    // Terminals
    terminals: [
      {text: "1", value: "15809"},
      {text: "2", value: "15389"},
      {text: "3", value: "15390"},
      // {text: "4", value: "004"},
      // {text: "5", value: "005"},
    ],
    selectedTerminals: [],

    // prols for datetime picker
    textFieldProps: {
        prependIcon: 'mdi-calendar',
        outlined: true,
        dense: true,
        filled: true,
        hideDetails: true
      }
  }),
  created() {
    // var fromMoment = moment(this.fromDate)
    // console.log("|", this.disableStalls, "|")
    // console.log("HELLo")
  },
  computed: {
    ...mapState('filter', ['globalYearFilter', 'globalEventFilter', 
                            'filterStallHolders', 'filterStalls', 'allStallHolders',
                            'allStalls']),
    selectedStallHolders: {
      get() {
        if (this.filterStallHolders == "all") {
          return this.allStallHolders
        }
        return this.filterStallHolders
      },
      set (value) {
        this.extractAllStalls(value)
        if (value.length == this.allStallHolders.length) {
          this.setFilterStallHolders("all")
        } else {
          this.setFilterStallHolders(value)
        }
      }
    },
    
    selectedStalls: {
      get() {
        return this.filterStalls
      },
      set (value) {
        this.setFilterStalls(value)
      }
    },
    stallSelectAllIcon() {
      if(this.selectedStalls.length === this.allStalls.length) {
        return "mdi-close-box"
      } else if (this.selectedStalls.length > 0) {
        return "mdi-minus-box"
      }
      return "mdi-checkbox-blank-outline"
    },
    terminalSelectAllIcon() {
      if(this.selectedTerminals.length === this.terminals.length) {
        return "mdi-close-box"
      } else if (this.selectedTerminals.length > 0) {
        return "mdi-minus-box"
      }
      return "mdi-checkbox-blank-outline"
    }
  },
  methods: {
    ...mapMutations('filter', ['setFilterStallHolders', 'setFilterStalls']),
    ...mapActions('filter', ['extractAllStalls']),
    selectAllStalls() {
      this.$nextTick(() => {
        if (this.selectedStalls.length === this.allStalls.length) {
          this.selectedStalls = []
        } else {
          this.selectedStalls = this.allStalls.slice()
        }
      })
    },
    selectAllTerminals() {
      this.$nextTick(() => {
        if (this.selectedTerminals.length === this.terminals.length) {
          this.selectedTerminals = []
        } else {
          this.selectedTerminals = this.terminals.map(x => x.value).slice()
        }
      })
    },
    selectAllStallHolders() {
      this.$nextTick(() => {
        if (this.selectedStallHolders.length === this.allStallHolders.length) {
          this.selectedStallHolders = []
        } else {
          this.selectedStallHolders = this.allStallHolders.slice()
        }
      })

    },
    testCall() {
      this.$emit("submit-pressed")
      // console.log("test")
      // console.log(this.selectedStallHolders)
      // axios.get('https://dashboard.eventpostest.com.au/takingsStallComparison', {
      //   params: {
      //     event: this.globalEventFilter,
      //     year: this.globalYearFilter,
      //     stalls: this.selectedStalls,
      //     stallHolders: this.selectedStallHolders,
      //     terminalIds: this.selectedTerminals
      //   },
      //   paramsSerializer: params => {
      //     return Qs.stringify(params, {arrayFormat: 'repeat'})
      //   }
      // }).then(response => {
      //   console.log(response.data)
      // })
    }
  }
}
</script>

<style scoped>
</style>