<template>
  <v-combobox
    v-model="selectedList"
    :items="dataList"
    :label="label"
    multiple
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="isDisable"
    @change="haddleClick"
    >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @mousedown.prevent
        @click="toggle"
        >
        <v-list-item-action>
            <v-icon :color="selectedList.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
            </v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
            Select All
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template  v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.StallholderName ? item.StallholderName : item.StallName ? item.StallName : item.TerminalNumber }}</span>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ selectedList.length - 1 }} others)
        </span>
    </template>
</v-combobox>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
    dataList: {
      type: Array,
      default: null
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedList: []
  }),
  computed: {
    likesAll () {
      return this.selectedList.length === this.dataList.length
    },
    likesSome () {
      return this.selectedList.length > 0 && !this.likesAll
    },
    icon () {
      if (this.likesAll) return 'mdi-close-box'
      if (this.likesSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.selectedList = []
          this.$emit('CheckVisibility', true);
          this.$emit('checkSelectedList', this.selectedList)
        } else {
          this.selectedList = this.dataList.slice()
          this.$emit('CheckVisibility', true);
          this.$emit('checkSelectedList', this.selectedList)
        }
      })
    },
    haddleClick () {
      this.$emit('CheckVisibility', true);
      this.$emit('checkSelectedList', this.selectedList)
    }
  }
}
</script>