<template>
  <div>
    <v-app-bar
      color=""
      dark
    >
      <v-app-bar-nav-icon @click="setDrawer(!drawer)"/>
      <v-tabs
        show-arrows
        center-active
        class="pr-2"
        optional
      >
        <v-tab v-for="(item, index) in menuItems" :key="index" :to="item.to">
          {{item.name}}
        </v-tab>
      </v-tabs>
      <!-- <v-btn color="secondary" v-if="isDevelopment" @click="makeAPICall">
        api call
      </v-btn> -->
      <label class="mr-5" style="color:gray; font-size: small;">{{selectedYear.Year}}</label>
      <label style="color:gray; font-size: small; width: 10%;">{{selectedEvent.EventName}}</label>
      <global-EventSelectorDesktop/>
      <!-- <utility-LogoutButton outlined class="d-sm-none d-md-flex"/> -->
    </v-app-bar>
    <global-drawer/>
  </div>
</template>

<script>

import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'

export default {
  data: () => ({
    menuItems: [
      { name: "Home", to: "/" },
      { name: "Takings", to: "/Takings" },
      { name: "Product Sales", to: "/ProductSales" },
      { name: "Transactions", to: "/Transactions" },
      { name: "Stock Levels", to: "/StockLevels" }
    ]
  }),
  computed: {
    ...mapState('app', ['drawer']),
    ...mapState('filter', ['globalYearFilter', 'globalEventFilter']),
    selectedYear: {
      get () {
        return this.globalYearFilter
      },
      set (value) {
        console.log("YEAR IS CHANGED", value)
      }
    },
    selectedEvent: {
      get() {
        return this.globalEventFilter
      },
      set (value) {
        console.log("EVENT IS CHANGED MAKE API REQUEST", value)
      }
    },
    isDevelopment : function() {
      return process.env.NODE_ENV == "development"
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    ...mapActions('dashboard', ['updateDashboard']),
    ...mapMutations('filter', ['setGlobalYearFilter', 'setGlobalEventFilter']),
    makeAPICall() {
      this.updateDashboard()
    }
  }

}
</script>

<style>

</style>