import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#000000',
        secondary: '#3e444c',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#000000',
        secondary: '#3e444c',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        borderColor: '#2b2e32',
  lightBackgroundColor: '#3e444c'
      },
    }
  },
});

/**
 * backgroundColor: #272b30
 * color: #c8c8c8
 * borderColor: #2b2e32
 * lightBackgroundColor: #3e444c
 */