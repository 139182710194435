/**
* Dashboard api for the home page
*/

import Dashboard from './dashboard'
// import Qs from 'qs'

export default class ProductSalesAPI extends Dashboard {
  constructor() {
    super()
  }

  encodeDataToURL = (params) => {
    let urlVals = Object
      .keys(params)
      .map(value => `${value}=${encodeURIComponent(params [value])}`)
      .join('&');
    return urlVals
  }

  getTotalTransactions(context) {

    // console.log('getTotalTransactions context: ', context)
    // console.log('getTotalTransactions searchStallholders: ', context.searchStallholders)
    
    let accessToken = localStorage.getItem('accessToken')
    let accessUser = localStorage.getItem('accessUser')

    let params = {
      eventId: context.eventId,
      startTime: context.searchStartTime,
      endTime: context.searchEndTime,
      stallholders: context.searchStallholders,
      stalls: context.searchStalls,
    }
    
    // console.log('PARAMS CHECK: ', params)
    let newParams = this. encodeDataToURL(params)
    // console.log('newParams: ', newParams)


    return this.api.get('/components/productsales?user='+ accessUser + '&accessToken=' + accessToken + '&' + newParams
    // {
    //   params,
    //   paramsSerializer: params => {
    //     // return Qs.stringify(params)
    //     return Qs.stringify(params, {arrayFormat: 'repeat'})
    //   }
    // }
    ).then(response => {
      return response.data
    })
  }

  // getProductsTable(context) {
  //   return this.api.get('/productsalesProductsTable', {
  //     params: {
  //       eventId: context.eventId,
  //       stallholderStallFilter: context.stallholderStallFilter,
  //       stalls: context.stalls
  //     },
  //     paramsSerializer: params => {
  //       return Qs.stringify(params, {arrayFormat: 'repeat'})
  //     }
  //   }).then(response => {
  //     return response.data
  //   })
  // }

  // getCategoriesTable(context) {
  //   return this.api.get('/productsalesCategoriesTable', {
  //     params: {
  //       eventId: context.eventId,
  //       stallholderStallFilter: context.stallholderStallFilter,
  //       stalls: context.stalls
  //     },
  //     paramsSerializer: params => {
  //       return Qs.stringify(params, {arrayFormat: 'repeat'})
  //     }
  //   }).then(response => {
  //     return response.data
  //   })
  // }
  
  // getStallComparisons(context) {
  //   return this.api.get('/productsalesStallComparison', {
  //     params: {
  //       eventId: context.eventId,
  //       stallholderStallFilter: context.stallholderStallFilter,
  //       stalls: context.stalls
  //     },
  //     paramsSerializer: params => {
  //       return Qs.stringify(params, {arrayFormat: 'repeat'})
  //     }
  //   }).then(response => {
  //     return response.data
  //   })
  // }

}