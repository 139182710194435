/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

// Store functionality
import modules from './modules'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  // plugins: [createPersistedState({
  //     storage: window.sessionStorage,
  // })],

  modules,
})

export default store
