export default {
  globalEventFilter: {},
  globalAllEventFilter: {},
  globalYearFilter: {},
  globalSelectedEvent: {},

  searchStartTime: 'notSet',
  searchEndTime: 'notSet',
  searchStallholders: 'notSet',
  searchStalls: 'notSet',

  filterStallHolders: "all",
  // filterStalls: "all",
  // filterTerminals: "all",
  // filterTerminalUsers: "all",
  filterYear: "",

  allYears: [],
  allEvents: [],
  // allFetchedEvents:[],
  // allTotalEvents: [],
  // EOD: {},
  IsAdmin: {},
  allStallHolders: [],
  allVouchers: [],
  allTerminalUsers: [],
  allStalls: [],
  // allTerminals: []

}