/**
* Wrapper around the Authclass from aws-amplify library
*/

import { Auth } from 'aws-amplify';

import { dashboardAPI } from '@/services'

export default class Authentication {
  constructor() {

    // every 60 min refresh the ID and Access Tokens
    setInterval(() => {
        Auth.currentSession().then(() => {
            console.log("User Session refreshed")
            Auth.currentAuthenticatedUser(user => {
                dashboardAPI.setHeader(user.signInUserSession.idToken.jwtToken)
            })
        }).catch(err => {
            console.log("Error refreshing session", err)
            location.reload()
        })
      }, 3600000)
  }

  signIn(username, password) {
      return Auth.signIn(username, password).then(user => {
          return user;
      })
  }

  signOut() {
      return Auth.signOut().then(response => {
          return response
      })
  }

  getAuth() {
      return Auth;
  }
}